import React, { useState } from 'react';
import './App.css';

import { withStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import ConnectC, { getEncData } from "./ConnectC/ConnectC.js";

import QrScanner from "./components/QrScanner";
import Modal from "./components/Modal";

function App() {

	return (
		<div className="App">{DrawPage()}</div>
	);
}

function DrawPage() {
	const [certId, setCertId] = useState(0);
	const [cert, setCert] = useState([]);
	const [qrScanner, setQrScanner] = useState(false);

	// const useStyles = makeStyles((theme) => ({
	// 	root: {
	// 		"fontSize": 20,
	// 		"fontWeight": 600
	// 	}
	// }));

	const CenteredListItemText = withStyles({
		root: {
			textAlign: "center",
			fontSize: 20,
			fontWeight: 500
		}
	})(ListItemText);

	//let encrypted = AES.encrypt('FullName;University;Degree;CertType;DATE', 'SomeRandomPassPhrase').toString();
	//console.log(encrypted);

	//TEST ENCODED DATA: U2FsdGVkX19yOa1x2zBT9tC3HfmJWYHo2BwTEsw8G+MEYt5kdhBZ0zmdGWCw2VGjsFMR1dDTUKqoHUvv/KCuFQ==
	//let decrypted = cert.encData ? Utf8.stringify(AES.decrypt(cert.encData, 'SomeRandomPassPhrase')).split(';') : null;
	//console.log(decrypted);

	const [decData, setDecData] = useState(0);
	function handleSubmit(event) {
		event.preventDefault();
		//console.log(certId);

		localStorage.setItem('certId', certId);
		ConnectC(certId).then((r) => {
			//console.log(r);
			r[0].length !== 0 ? setCert(r) : alert("Certificate not found");
		});
		//Reset decrypted data
		setDecData([]);
	}

	const [password, setPassword] = useState('');
	return (<div className="connecting">
		<br />
		<form onSubmit={handleSubmit}>
			<TextField type='text' label='Certificate Serial Number' name='certID' variant='outlined' onChange={(e) => setCertId(e.target.value)} autoComplete='off' />
			<p><Button variant="contained" type="submit">Submit</Button></p>
		</form>
		<p><Button variant="contained" type="submit" onClick={() => setQrScanner(true)}>Scan QR Code</Button></p>
		<List style={{ display: cert.rawData ? "block" : "none" }}>
			{/* <ListItem divider><CenteredListItemText disableTypography primary={"Student ID: "+ cert.rawData} /></ListItem> */}
			<ListItem divider><CenteredListItemText disableTypography primary={"ID Certifikates: " + cert.rawData} /></ListItem>
			<ListItem divider><CenteredListItemText disableTypography primary={"Leshuar: " + new Date(cert.created * 1000).toLocaleString('en-GB')} /></ListItem>
			{/* <ListItem divider><CenteredListItemText disableTypography primary={"Certificate is " + (Number(cert.state) === 0 ? "Pending" : Number(cert.state) === 1 ? "Valid" : Number(cert.state) === 2 ? "Declined" : '')} /></ListItem> */}
			<ListItem divider selected button onClick={async () => {
				let tempPass = prompt("Enter decryption key")
				setPassword(tempPass);
				let decrypted = await getEncData(cert.encData, cert.photoHash, tempPass);
				setDecData(decrypted);
			}}>
				<CenteredListItemText disableTypography primary={"Open Additional Info"} />
			</ListItem>
			<Modal decData={decData} password={password}/>
		</List>
		{qrScanner ? <QrScanner/> : ''}
	</div>
	);
}

export default App;
