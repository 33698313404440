import Web3 from 'web3'
//import { useState, useEffect } from 'react';
import AES from 'crypto-js/aes';
import Utf8 from 'crypto-js/enc-utf8';
import * as ipfsClient from "ipfs-http-client";

import {contractAbi} from './abi.js'
import {contractAddress, network, ipfsGateway} from './config.js'

function ConnectC(id) {
	
	// Initialize Web3
	const web3 = new Web3(new Web3.providers.HttpProvider(network));

	// Set Account
	web3.eth.defaultAccount = web3.eth.accounts[0];

	// Set the Contract
	let contract = new web3.eth.Contract(contractAbi, contractAddress);
	console.log(ascii_to_hexa(id));

	const data = contract.methods.certs(ascii_to_hexa(id)).call();
	//console.log(data);
	return data;
}

function ascii_to_hexa(str) {
	var arr1 = [];
	for (var n = 0, l = str.length; n < l; n ++) {
		var hex = Number(str.charCodeAt(n)).toString(16);
		arr1.push(hex);
	}
	return "0x"+arr1.join('');
}

export async function getEncData(encData, photoHash, pass) {
	let decrypted;
	try {
		decrypted = AES.decrypt(encData, pass).toString(Utf8).split(';');

		{	// Decrypting image
			const ipfs = ipfsClient(ipfsGateway);

			const chunks = [];
			for await (const chunk of ipfs.cat(photoHash)) {
				let chunkString = new TextDecoder().decode(chunk);
				chunks.push(chunkString);
			}
			decrypted.push(AES.decrypt(chunks.join(''), "1234").toString(Utf8));
		}

		if(decrypted.length === 1)
			throw new Error("Empty decryption");
	}
	catch(err) {
		//console.log(err);
		return err;
	}

	return decrypted;
}

export default ConnectC;