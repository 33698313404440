import React, { useState, useEffect } from 'react'

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Avatar from '@material-ui/core/Avatar';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import Container from '@material-ui/core/Container';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import CV from './CV'

import proj4 from 'proj4';

export default function Modal(props) {
    const [decOpen, setDecOpened] = useState(false);
    const [decFail, setDecFail] = useState(false);

    const decData = props.decData;
    const password = props.password;

    const krgjsh = "+proj=tmerc +lat_0=0 +lon_0=20 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs ";
    const google = "+proj=longlat +datum=WGS84 +no_defs "; //WGS84
    const googleCords = decData[8] ? proj4(krgjsh,google,decData[8].split(",").map(e => parseFloat(e))) : "";

    useEffect(() => {
        //console.log(decData.length);
        if (decData.length === 11)
            setDecOpened(true);

        if (decData instanceof Error)
            setDecFail(true);

    }, [decData])
    return (
        <div className="encData">
            <Dialog className="decData" open={decOpen} fullScreen onClose={() => { setDecOpened(false) }}>
                <DialogContent>
                    <Container>
                        <Avatar variant='rounded' style={{ width: 256, height: 256, margin: "0 auto" }} src={decData[10]} alt='Photo' />
                    </Container>
                <TableContainer style={{display: "flex"}}>
                    <Table size="small" style={{flex: "50%"}}>
                        <TableBody>
                            <TableCell>
                                <TableRow>
                                    <TableCell variant="head">Pronaret:</TableCell><TableCell>{decData[0]}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell variant="head">ID e pronareve:</TableCell><TableCell>{decData[1]}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell variant="head">Pjesa per pronar:</TableCell><TableCell>{decData[2]}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell variant="head">Numri i pasurise:</TableCell><TableCell>{decData[3]}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell variant="head">Zona kadastrale:</TableCell><TableCell>{decData[4]}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell variant="head">Siperfaqja (m2):</TableCell><TableCell>{decData[5]}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell variant="head">Vlera totale:</TableCell><TableCell>{decData[6]}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell variant="head">Adresa e plote e pasurise:</TableCell><TableCell>{decData[7]}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell variant="head">Koordinatat (KRGJSH):</TableCell><TableCell>{decData[8]}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell variant="head">Lloji i certifikates:</TableCell><TableCell>{decData[9]}</TableCell>
                                </TableRow>
                            </TableCell>
                        </TableBody>
                    </Table>
                    <div style={{ width: "100%"}}><iframe title={decData[3]} width="100%" height="100%" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src={`https://maps.google.com/maps?width=100%25&height=100%25&hl=en&q=${googleCords[1]},${googleCords[0]}+(${decData[3]})&t=h&z=16&ie=UTF8&iwloc=B&output=embed`}></iframe></div>
                </TableContainer>
                    {/* <Box textAlign="center">
                        <Button style={{ justifyContent: 'center' }} variant="contained" type="submit" onClick={
                            () => {
                                CV(decData, password);
                            }
                        }>Download Report</Button>
                    </Box> */}
                </DialogContent>
            </Dialog>
            <Dialog open={decFail} onClose={() => { setDecFail(false) }}><p>Decryption Failed</p></Dialog>
        </div>
    );
}